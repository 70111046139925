<template>
<div>
   <!-- BOC:[header] -->
   <v-row class="mb-3">
      <v-col class="text-right">
        <v-btn
          color="primary"
          large
          class="mr-2"
          :to="{
            name: 'PageModeratorBookChapterPageAdd',
            params: {
              parentId: parent.id,
              parent: parent,
              grandParentId: grandParent.id,
              grandParent: grandParent,
            },
          }"
        >
         + New Page
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
  <!-- BOC:[table] -->
  <BreadBrowseTable
    role="Moderator"
    :model="model"
    :url="`${$api.servers.game}/api/v1/en/moderator/book/${$route.params.parentId}/chapter/${parent.id}/page`"
    :isSearchable="true"
    :parent="parent"
    :modelParent="modelParent"
    :grandParent="grandParent"
    :modelGrandParent="modelGrandParent"
  ></BreadBrowseTable>
  <!-- EOC -->
</div>
</template>

<script>
  //BOC:[model]
  import modelGrandParent from '@/models/items/book'
  import modelParent from '@/models/items/chapter'
  import model from '@/models/items/page'
  //EOC
  //BOC:[table]
  import BreadBrowseTable from '@/components/Bread/BreadBrowseTableV2'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //BOC:[table]
      BreadBrowseTable,
      //EOC
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'role',
      'parent',
      'grandParent',
    ],
    data:()=>({
      //BOC:[model]
      modelParent:modelParent,
      modelGrandParent: modelGrandParent,
      model: model,
      //EOC
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>