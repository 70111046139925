<template>
  <v-btn
    color="error"
    large
    :to="$_getRouteChildDelete(role,model.key,$route.params.id,me,modelParent.key,$route.params.parentId,parent)"
  >
    {{$t("action.delete")}}
  </v-btn>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'me',
      'parent',
      'role',
      'model',
      'modelParent',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>