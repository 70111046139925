<template>
  <v-card>
    <v-card-text v-if="api.isError">
      <AErrorV1 :api="api"></AErrorV1>
    </v-card-text>
    <v-card-text v-if="isSearchable">
      <div class="d-flex">
        <v-text-field
          v-model="search"
          :label="$t('string.search_by_name')"
          single-line
          hide-details
        ></v-text-field>
        <div>
          <v-btn color="primary" :loading="isLoading" @click="searchData"
            >Search <v-icon right> mdi-magnify </v-icon></v-btn
          >
        </div>
      </div>
      <!-- BOC:[loader] -->
      <ALoader :isLoading="api.isLoading"></ALoader>
      <!-- EOC -->
    </v-card-text>
    <DataTable
      v-if="!api.isLoading"
      bread="browse"
      :api="api"
      :items="items"
      :role="role"
      :model="model"
      :limit="limit"
      :page="page"
      :headers="headers"
      :expandable="expandable"
      :parent="parent"
      :modelParent="modelParent"
      :grandParent="grandParent"
      :modelGrandParent="modelGrandParent"
      @refetch="refetchClassroom"
    ></DataTable>
    <template>
      <div class="text-center" v-if="!api.isLoading">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="totalPages"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div> </template
  ></v-card>
</template>


<script>
import DataTable from "@/components/Bread/DataTableV2";
import { mapState } from "vuex";
export default {
  components: {
    DataTable,
  },
  computed: mapState({
    //
  }),
  props: [
    "role",
    "model",
    "url",
    "isTrashed",
    "isSearchable",
    "parent",
    "modelParent",
    "grandParent",
    "modelGrandParent",
  ],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[table]
    items: [],
    //EOC
    //BOC:[search]
    search: "",
    //EOC
    //BOC:[headers]
    headers: [],
    //EOC
    //BOC:[expandable]
    expandable: false,
    //EOC
    //BOC:[pagination]
    page: 1,
    totalPages: 5,
    limit: 10,
    isLoading: false,
    //EOC
  }),
  watch: {
    page: function () {
      this.fetch();
    },
    search: function (val) {
      this.search = val;
    },
  },
  created() {
    //BOC:[headers]
    this.headers = this.$_.cloneDeep(
      this.model[this.role].browse.table.headers
    );
    if (this.model[this.role].read) {
      if (this.isTrashed) {
        this.headers.push({
          text: "",
          value: "actionTrashed",
          sortable: false,
          filterable: false,
        });
      } else {
        this.headers.push({
          text: "",
          value: "actionView",
          sortable: false,
          filterable: false,
        });
      }
    }
    //EOC
    //BOC:[expandable]
    this.expandable =
      this.$_.findIndex(this.model[this.role].browse.table.headers, {
        value: "data-table-expand",
      }) > -1
        ? true
        : false;
    //EOC
  },
  mounted() {
    this.fetch();
  },
  methods: {
    searchData() {
      this.page = 1;
      this.isLoading = true;
      this.fetch();
    },
    //
    fetch() {
      //BOC:[api]
      this.api.method = "get";
      this.api.url = this.url + "?page=" + this.page + "&search=" + this.search;
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        //BOC:modify count data
        resp.data.forEach((item) => {
          if (typeof item._count != "undefined") {
            Object.keys(item._count).forEach((key) => {
              item["count" + key] = item._count[key];
            });
          }
        });
        //EOC
        this.isLoading = false;
        this.items = resp.data;
        this.page = resp.page;
        this.totalPages = resp.totalPages;
      };
      //EOC
      this.$api.fetch(this.api);
    },
    refetchClassroom() {
      if (this.model.key == "EventSchoolYear") {
        this.api.url = `${this.$api.servers.event}/api/v1/en/moderator/event/${this.$route.params.id}/schoolYear`;
      }

      this.fetch();
    },
  },
};
</script>